import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "type", "college", "prepost", "fields", "username", "password", "submit" ]

  update() {
    const selected_type = this.selectedType()
    if (selected_type) {
      const common_app = (selected_type.dataset.commonApp == "true")
      const requires_college = (selected_type.dataset.requireCollege == "true")

      if (requires_college) {
        this.showCollege()

        if (this.selectedCollege()) {
          if (common_app) {
            // prepost not required
            this.hidePrepost()
            this.showFields()
          } else {
            this.showPrepost()

            if (this.selectedPrepost()) {
              this.showFields()
            } else {
              this.hideFields()
            }
          }

        } else {
          this.hidePrepost()
          this.hideFields()
          return
        }

      } else {
        // college not required
        this.hideCollege()

        if (common_app) {
          this.hidePrepost()
          this.showFields()

        } else {
          this.hidePrepost()
          this.showFields()
        }
      }

      if (this.enteredPassword()) {
        this.enableSubmit()
      } else {
        this.disableSubmit()
      }

    } else {
      this.hideCollege()
      this.hidePrepost()
      this.hideFields()
    }
  }

  selectedType() {
    const selected = this.typeTarget.querySelector('select').selectedOptions[0]
    if (selected && selected.value != "") { return selected }
  }

  selectedCollege() {
    const selected = this.collegeTarget.querySelector('select').selectedOptions[0]
    if (selected && selected.value != "") { return selected }
  }

  selectedPrepost() {
    return this.prepostTarget.querySelector(':checked')
  }

  enteredPassword() {
    return this.usernameTarget.value.trim().length > 0 && this.passwordTarget.value.trim().length > 0
  }

  hideCollege() {
    this.collegeTarget.classList.add('zurb-hide')
    this.collegeTarget.querySelector('select').value = ''
  }

  hidePrepost() {
    this.prepostTarget.classList.add('zurb-hide')
    const selected = this.prepostTarget.querySelector(':checked')
    if (selected) { selected.checked = false }
  }

  hideFields() {
    this.fieldsTarget.classList.add('zurb-hide')
  }

  showCollege() {
    this.collegeTarget.classList.remove('zurb-hide')
  }

  showPrepost() {
    this.prepostTarget.classList.remove('zurb-hide')
  }

  showFields() {
    this.fieldsTarget.classList.remove('zurb-hide')
  }

  updateSubmit() {
    if (this.enteredPassword()) {
      this.enableSubmit()
    } else {
      this.disableSubmit()
    }
  }

  disableSubmit() {
    this.submitTarget.disabled = true
  }

  enableSubmit() {
    this.submitTarget.disabled = false
  }
}
