import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'togglee' ]
  static values = { url: String, id: Number }

  update() {
    const csrfToken = document.head.querySelector("meta[name='csrf-token']").getAttribute("content");

    let formData = new FormData();
    formData.append('rating', this.element.value);

    fetch(this.urlValue, {
      method: "POST",
      headers: { "X-CSRF-Token": csrfToken },
      body: formData
    }).then(response => {
      if (!response.ok) { throw response; }
      return response
    }).then((data) => {
      this.notifyOther();
      return true
    });
  }

  notifyOther() {
    let event = new CustomEvent('rating:notify', { detail: { id: this.idValue, rating: this.element.value } })
    window.dispatchEvent(event)
  }

  notified(e) {
    if (this.idValue == e.detail.id) {
      this.element.value = e.detail.rating;
    }
  }
}
