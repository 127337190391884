/* eslint no-console:0 */

const images = require.context('../theme/images', true)

import 'jquery'
import 'theme'
import 'portal'
import 'controllers/portal'
import 'controllers/shared'

window.$ = window.jQuery = require('jquery')

// error reporting
import { appsignal } from "vendor/appsignal";
